






















// TODO: follow design exactly
// TODO: break email address when username has more than 15 characters and break at @
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import { getSearchUser } from "@/utils/backend";

const NOT_AVAILABLE = "Not Available";

export default Vue.extend({
  props: {
    id: {
      type: [Number, String],
    },
  },
  name: "MemberProfile",
  computed: {
    ...mapGetters(["professions"]),
    professionText() {
      const NOT_AVAILABLE = "Not Available";
      let result = NOT_AVAILABLE;

      if (this.member && (this.member as any).profession_id) {
        const profession = this.professions.find(
          (p) => "" + p.id === "" + (this.member as any).profession_id
        );

        if (profession) {
          result = profession.title;
        }
      }

      return result;
    },
    socialProfile() {
      
      let result = NOT_AVAILABLE;

      if (this.member && (this.member as any).social_profile) {
        result = (this.member as any).social_profile;
      }

      return result;
    },
  },
  data() {
    return {
      member: {},
      NOT_AVAILABLE,
    };
  },
  methods: {
    ...mapActions(["showLoading", "hideLoading", "displayMessage"]),
    async fetch() {
      this.showLoading();
      const result = await getSearchUser(this.id);

      this.member = result;
      this.hideLoading();
    },
    onReturnClick() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.fetch();
  },
});
